@import '../../assets/css/breakpoints.scss';

.root {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 20px;
    @include mq('md') {
        left: 55px;
    }
   .vertical_mode {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        -webkit-transform: translate(-50%, -50%) rotate(-90deg);
        -moz-transform: translate(-50%, -50%) rotate(-90deg);
        -ms-transform: translate(-50%, -50%) rotate(-90deg);
        -o-transform: translate(-50%, -50%) rotate(-90deg);
        width: 400px;
        position: absolute;
        text-align: center;
        img {
            transform: rotate(90deg);
            width: 25px;
            margin-right: 20px;
        }
        span {
            font-size: 14px;
            font-weight: normal;
            border-top: 1px solid var(--primary-color);
            margin-left: 12px;
            padding-top: 4px;
        }
   }
}