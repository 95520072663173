.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 20px;
}

.link-container {
    display: flex;
    flex-direction: row;
}

.link {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    color: #35858F;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
}
