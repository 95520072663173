@import '../../assets/css/breakpoints.scss';

.root {
    .circles1 {
        position: absolute;
        top: 222px;
        left: 133px;
        z-index: -1;
        display: none;
        @include mq('md') {
            display: block;
        }
    }
    .circles2 {
        position: absolute;
        top: 550px;
        right: 0px;
        z-index: -1;
        display: none;
        @include mq('md') {
            display: block;
        }
    }
    .circles3 {
        position: absolute;
        top: 0px;
        right: 0;
        z-index: -1;
    }
}
