@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap');

:root {
  --primary-color: #2C6B78;
}

body {
  color: var(--primary-color);
}
a {
  text-decoration: none;
  color: #2C6B78;
}
p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
