@import './breakpoints.scss';

.p-1 { padding: 4px; }
.pt-1 { padding-top: 4px; }
.pb-1 { padding-bottom: 4px; }
.pl-1 { padding-left: 4px; }
.pr-1 { padding-right: 4px; }
.px-1 { padding: 0 4px; }
.py-1 { padding: 4px 0; }

.p-2 { padding: 8px; }
.pt-2 { padding-top: 8px; }
.pb-2 { padding-bottom: 8px; }
.pl-2 { padding-left: 8px; }
.pr-2 { padding-right: 8px; }
.px-2 { padding: 0 8px; }
.py-2 { padding: 8px 0; }

.p-3 { padding: 12px; }
.pt-3 { padding-top: 12px; }
.pb-3 { padding-bottom: 12px; }
.pl-3 { padding-left: 12px; }
.pr-3 { padding-right: 12px; }
.px-3 { padding: 0 12px; }
.py-3 { padding: 12px 0; }

.p-4 { padding: 16px; }
.pt-4 { padding-top: 16px; }
.pb-4 { padding-bottom: 16px; }
.pl-4 { padding-left: 16px; }
.pr-4 { padding-right: 16px; }
.px-4 { padding: 0 16px; }
.py-4 { padding: 16px 0; }

.p-5 { padding: 20px; }
.pt-5 { padding-top: 20px; }
.pb-5 { padding-bottom: 20px; }
.pl-5 { padding-left: 20px; }
.pr-5 { padding-right: 20px; }
.px-5 { padding: 0 20px; }
.py-5 { padding: 20px 0; }

.p-6 { padding: 24px; }
.pt-6 { padding-top: 24px; }
.pb-6 { padding-bottom: 24px; }
.pl-6 { padding-left: 24px; }
.pr-6 { padding-right: 24px; }
.px-6 { padding: 0 24px; }
.py-6 { padding: 24px 0; }

.p-7 { padding: 28px; }
.pt-7 { padding-top: 28px; }
.pb-7 { padding-bottom: 28px; }
.pl-7 { padding-left: 28px; }
.pr-7 { padding-right: 28px; }
.px-7 { padding: 0 28px; }
.py-7 { padding: 28px 0; }

.p-8 { padding: 32px; }
.pt-8 { padding-top: 32px; }
.pb-8 { padding-bottom: 32px; }
.pl-8 { padding-left: 32px; }
.pr-8 { padding-right: 32px; }
.px-8 { padding: 0 32px; }
.py-8 { padding: 32px 0; }

.p-9 { padding: 36px; }
.pt-9 { padding-top: 36px; }
.pb-9 { padding-bottom: 36px; }
.pl-9 { padding-left: 36px; }
.pr-9 { padding-right: 36px; }
.px-9 { padding: 0 36px; }
.py-9 { padding: 36px 0; }

.p-10 { padding: 40px; }
.pt-10 { padding-top: 40px; }
.pb-10 { padding-bottom: 40px; }
.pl-10 { padding-left: 40px; }
.pr-10 { padding-right: 40px; }
.px-10 { padding: 0 40px; }
.py-10 { padding: 40px 0; }

.m-1 { margin: 4px; }
.mt-1 { margin-top: 4px; }
.mb-1 { margin-bottom: 4px; }
.ml-1 { margin-left: 4px; }
.mr-1 { margin-right: 4px; }
.mx-1 { margin: 0 4px; }
.my-1 { margin: 4px 0; }

.m-2 { margin: 8px; }
.mt-2 { margin-top: 8px; }
.mb-2 { margin-bottom: 8px; }
.ml-2 { margin-left: 8px; }
.mr-2 { margin-right: 8px; }
.mx-2 { margin: 0 8px; }
.my-2 { margin: 8px 0; }

.m-3 { margin: 12px; }
.mt-3 { margin-top: 12px; }
.mb-3 { margin-bottom: 12px; }
.ml-3 { margin-left: 12px; }
.mr-3 { margin-right: 12px; }
.mx-3 { margin: 0 12px; }
.my-3 { margin: 12px 0; }

.m-4 { margin: 16px; }
.mt-4 { margin-top: 16px; }
.mb-4 { margin-bottom: 16px; }
.ml-4 { margin-left: 16px; }
.mr-4 { margin-right: 16px; }
.mx-4 { margin: 0 16px; }
.my-4 { margin: 16px 0; }

.m-5 { margin: 20px; }
.mt-5 { margin-top: 20px; }
.mb-5 { margin-bottom: 20px; }
.ml-5 { margin-left: 20px; }
.mr-5 { margin-right: 20px; }
.mx-5 { margin: 0 20px; }
.my-5 { margin: 20px 0; }

.m-6 { margin: 24px; }
.mt-6 { margin-top: 24px; }
.mb-6 { margin-bottom: 24px; }
.ml-6 { margin-left: 24px; }
.mr-6 { margin-right: 24px; }
.mx-6 { margin: 0 24px; }
.my-6 { margin: 24px 0; }

.m-7 { margin: 28px; }
.mt-7 { margin-top: 28px; }
.mb-7 { margin-bottom: 28px; }
.ml-7 { margin-left: 28px; }
.mr-7 { margin-right: 28px; }
.mx-7 { margin: 0 28px; }
.my-7 { margin: 28px 0; }

.m-8 { margin: 32px; }
.mt-8 { margin-top: 32px; }
.mb-8 { margin-bottom: 32px; }
.ml-8 { margin-left: 32px; }
.mr-8 { margin-right: 32px; }
.mx-8 { margin: 0 32px; }
.my-8 { margin: 32px 0; }

.m-9 { margin: 36px; }
.mt-9 { margin-top: 36px; }
.mb-9 { margin-bottom: 36px; }
.ml-9 { margin-left: 36px; }
.mr-9 { margin-right: 36px; }
.mx-9 { margin: 0 36px; }
.my-9 { margin: 36px 0; }

.m-10 { margin: 40px; }
.mt-10 { margin-top: 40px; }
.mb-10 { margin-bottom: 40px; }
.ml-10 { margin-left: 40px; }
.mr-10 { margin-right: 40px; }
.mx-10 { margin: 0 40px; }
.my-10 { margin: 40px 0; }

.opacity-100 {
  opacity: 1;
}
.opacity-50 {
  opacity: 0.5;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.text-center {
  text-align: center;
}
.text-12 {
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  line-height: 16px;
}
.text-15 {
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  line-height: 20px;
}
.text-16 {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  line-height: 22px;
}
.text-19 {
  font-family: 'Nunito', sans-serif;
  font-size: 19px;
  line-height: 26px;
}
.text-21 {
  font-family: 'Nunito', sans-serif;
  font-size: 21px;
  line-height: 29px;
}
.text-30 {
  font-family: 'Nunito', sans-serif;
  font-size: 30px;
  line-height: 41px;
}
.text-49 {
  font-family: 'Nunito', sans-serif;
  font-size: 49px;
  line-height: 67px;
}
.text-bold {
  font-weight: bold;
}
.text-white {
  color: white !important;
}
.cursor-pointer { cursor: pointer; }
.notif-box {
  text-align: center;
  padding-top: 32px;
  font-size: 16px;
  font-weight: bold;
}
.error-msg {
  font-size: 14px !important;
  color: red;
}
.error {
  color: red;
}
.success {
  color: #17E7D6;
}
.full-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}
.full-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
.pdf-button {
  padding: 12px 32px;
  background-color: #2C6B78;
  color: white;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  font-size: 16px;
}
.pdf-button:hover {
  opacity: 0.8;
}