@import '../../assets/css/breakpoints.scss';

.termsContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        width: 100%;
        max-width: 560px;
        height: 90%;
        max-height: 675px;
        border-radius: 12px;
        padding: 24px;
        background-color: white;
        z-index: 300;
        overflow: auto;
        p, li {
            font-family: 'Nunito', sans-serif;
            font-size: 12px;
            line-height: 20px;
        }
        .activeBtn {
            color: white;
            background-color: var(--primary-color);
            border-radius: 4px;
            padding: 8px 32px;
            border: none;
            outline: none;
            font-size: 14px;
            cursor: pointer;
        }
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.7);
        z-index: -1;
    }
}